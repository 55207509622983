import React from 'react';
import '../css/App.css';
import Header from './Header';
import Bio from './Bio';
import Reviews from './Reviews';
import Text from './Text';

function App() {
  return (
    <>
      <Header />
      <Text />
      <Bio />
      <Reviews />
    </>
  );
}

export default App;

import React from "react";

export default function Header() {
  return (
    <div className="header-main">
      <span className="header-name">Theodore M Davis</span>
      <span className="header-address">
        1038 Jackson Ave Long Island City, NY 11101
      </span>
      <span className="header-phone"><a href="tel:17183616789">(718) 361-6789</a></span>
    </div>
  );
}

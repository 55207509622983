import React from "react";

export default function Reviews() {
  return (
    <div className="Reviews-main">
      <div className="Reviews-header">Reviews from past clients</div>
      <div className="Reviews-item">
        Theodore Davis is a very good lawyer! He is very experienced! Everything
        makes in time, never rude (I’m not sure what previously comments talking
        about!). of course he wants his money first (as all people do) because
        non one of us doesn’t want work for free, right? So we all have to make
        sure that we get paid for what we are doing. But compare with other
        layers he don’t charge a lot and finished his job first without leaving
        everything behind. -Anastasiia Bondarenko
      </div>
      <div className="Reviews-item">
        Great lawyer with great team!!! Thanks guys for all your help. I highly
        recommend their services. -Ndiaye Freeda
      </div>
      <div className="Reviews-item">
        Theodore Davis is a great lawyer.he take is time to make sure your case
        doing well.he is a incredible lawyer endowed with a great experience.
        -Gaouss Cisse
      </div>
      <div className="Reviews-item">
        Thanks so much Davis and team you guys doing great job my case was
        successful you change my life thanks. -CAM ON Media
      </div>
      <div className="Reviews-item">
        Great customer service , really helped me . I will recommend him.
        -Peged-wende Tassembedo
      </div>
    </div>
  );
}

import React from "react";
import ProfilePhoto from "../images/profile-photo.jpg";

export default function Bio() {
  return (
    <div className="Bio-main">
      <img src={ProfilePhoto} alt="" width="20%" height="20%" />
      <span>
        <div className="Bio-item">
          <b>Education</b>
          <ul>
            <li>New York Law School, J.D., 1996</li>
            <li>William and Mary, B.A., 1989</li>
          </ul>
        </div>
        <div className="Bio-item">
          <b>Bar Admissions</b>
          <ul>
            <li>New York, 1997</li>
            <li>Connecticut, 1997</li>
            <li>Licensed Solicitor, England and Wales</li>
          </ul>
        </div>
        <div className="Bio-item">
          <b>Court Admissions</b>
          <ul>
            <li>United States Supreme Court</li>
            <li>United States District Court, District of Connecticut</li>
            <li>United States District Court, Eastern District of New York</li>
            <li>United States District Court, Southern District of New York</li>
          </ul>
        </div>
      </span>
    </div>
  );
}
